import { useAtomValue, useSetAtom } from 'jotai';
import { Billing_Type_Enum } from 'kheops-graphql';
import { ComputePackagingPriceFromOrderItem, ComputePackagingWeight, round } from 'kheops-utils';
import { useEffect, useState } from 'react';
import { useOrderByReferenceIdLazyQuery } from '../../queries/__generated__/orderByReferenceId.generated';
import { useGetOrderNextActionsLazyQuery } from '../../queries/__generated__/orderNextActions.generated';
import { ContractOrder, currentOrderItemsAtom, orderActionsAtom, orderAtom, orderDeliveryDateAtom, OrderDisplay, orderSddPaymentDateAtom } from '../state/state';
import { currentContextAtom, userAtom } from '../../state';

interface OrderProviderProps {
  orderReferenceId: string;
  children: React.ReactNode;
}
export default function OrderProvider({ orderReferenceId, children }: OrderProviderProps): React.JSX.Element {
  const [getOrderByReferenceIdLazyQuery, { data: orderData }] = useOrderByReferenceIdLazyQuery();
  const [getOrderNextActionsLazyQuery, { data: nextActionsData }] = useGetOrderNextActionsLazyQuery();
  const { companyId } = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const setOrderAtom = useSetAtom(orderAtom);
  const setOrderDeliveryDateAtom = useSetAtom(orderDeliveryDateAtom);
  const setOrderSddPaymentDate = useSetAtom(orderSddPaymentDateAtom);
  const setOrderActionsAtom = useSetAtom(orderActionsAtom);
  const setCurrentItems = useSetAtom(currentOrderItemsAtom);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    getOrderByReferenceIdLazyQuery({
      variables: { orderReferenceId },
      // to prevent having deprecated version in case the order was updated by another user
      fetchPolicy: 'network-only',
    });
    getOrderNextActionsLazyQuery({
      variables: {
        orderReferenceId,
        userId,
        userCompanyId: companyId,
      },
      // refetch to avoid displaying action buttons when its not needed
      fetchPolicy: 'network-only',
    });
  }, []);

  useEffect(() => {
    // check if getOrderNextActions query is successfull before setting states
    if (orderData && nextActionsData?.getOrderNextActions) {
      const order = orderData.order[0];
      const orderDisplay: OrderDisplay = {
        ...order,
        contract: order.contract as ContractOrder,
        items: order.items.map((item) => {
          return {
            ...item,
            defaultPrice: item.price,
            packaging: {
              ...item.packaging,
              price: ComputePackagingPriceFromOrderItem(item, item.packaging.base_unit!),
            },
          };
        }),
        previousOrders: orderData.previous_versions,
        delivery_date: order.delivery_date,
        buying_company_formatted_address: order.contract.buying_company.addresses[0].formattedAddress,
      };

      setOrderActionsAtom(nextActionsData.getOrderNextActions);
      setOrderAtom(orderDisplay);

      setOrderDeliveryDateAtom(orderDisplay.delivery_date ? new Date(orderDisplay.delivery_date) : undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setOrderSddPaymentDate(orderDisplay.sdd_payment_date ? new Date(orderDisplay.sdd_payment_date as any) : undefined);
      setCurrentItems(orderDisplay.items.map((item) => {
        const previousVersionItems = orderDisplay.previousOrders[0]?.items.find(
          (previousItem) => previousItem.packagingId === item.packaging.id,
        );
        const roundedWeight = item.weight ? round(item.weight, 3) : undefined;
        const notPresentInPreviousVersion = !previousVersionItems && !!orderDisplay.previousOrders[0]?.items.length;

        return {
          ...item,
          weight: roundedWeight,
          defaultQuantity: item.quantity,
          previousQuantity: notPresentInPreviousVersion ? 0 : previousVersionItems?.quantity,
          price: item.price,
          previousPrice: previousVersionItems?.price,
          previousWeight: previousVersionItems?.weight ? round(previousVersionItems.weight, 3) : undefined,
          defaultWeight: roundedWeight,
          defaultPackagingWeight: item.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent ? ComputePackagingWeight(item.packaging, item.packaging.base_unit!) : undefined,
          notPresentInPreviousVersion,
        };
      }));
      setIsReady(true);
    }
  }, [orderData, nextActionsData]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isReady && (children)}
    </>
  );
}
