import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Availability_Status_Enum, Business_Profile_Enum } from 'kheops-graphql';
import { currentBasketItemsAtom, currentBuyingCompanyIdAtom } from '../../basket/state/state';
import { contractsAtom, currentContextAtom } from '../../state';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { usePackagingsByBarcodeLazyQuery } from '../../queries/__generated__/packagingsByBarcode.generated';

interface useUpdateBasketReturn {
  upsertPackagingByBarCode: (barcode: string) => void;
}

/**
 * This hooks only works in an algolia instant search context
 */
export default function useUpdateBasketByBarCode(): useUpdateBasketReturn {
  const { t } = useTranslation(['basket']);
  const { realm, companyId: contextCompanyId } = useAtomValue(currentContextAtom);
  const editBasketItem = useSetAtom(currentBasketItemsAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [packagingsByBarcodeQuery, { data: packagingsByBarcodeData, loading }] = usePackagingsByBarcodeLazyQuery({
    fetchPolicy: 'network-only',
  });
  const currentBuyingCompanyId = useAtomValue(currentBuyingCompanyIdAtom);
  const contracts = useAtomValue(contractsAtom);
  const basketQuantities = useAtomValue(currentBasketItemsAtom);

  useEffect((): void => {
    if (!packagingsByBarcodeData?.packaging.length || loading) {
      return;
    }

    const packagings = realm === Business_Profile_Enum.Buyer
      ? packagingsByBarcodeData.packaging
      : packagingsByBarcodeData.packaging.filter((packaging) => (packaging.company.id === contextCompanyId));

    if (packagings.length === 1) {
      const packaging = packagings[0];

      const contract = contracts.find(({ buyingCompanyId, supplyingCompanyId }) => buyingCompanyId === currentBuyingCompanyId && supplyingCompanyId === packagings[0].company.id);

      if (!contract) {
        return;
      }

      if (packaging.base_unit.availability === Availability_Status_Enum.Unavailable) {
        setCommonSnackbarProps({
          label: t('basket:product_not_added_to_basket_unavailable_packaging', { product_name: packaging.product.name }),
          snackbarProps: {
            open: true,
          },
          alertProps: {
            severity: 'warning',
          },
        });
      } else {
        const basketItem = basketQuantities.get(`${packaging.id}_${contract.reference_id}`);

        editBasketItem({
          id: basketItem?.id,
          packagingId: packaging.id,
          quantity: basketItem?.quantity ? basketItem.quantity + 1 : 1,
          contractId: contract.reference_id!,
        });
      }
    } else if (packagings.length > 1) {
      const potentialPackagingCompanyId = packagings[0].company.id;
      const isPackagingCreatedByOtherSupplier = packagings.some(({ company }) => company.id !== potentialPackagingCompanyId);

      setCommonSnackbarProps({
        label: t(`basket:${
          isPackagingCreatedByOtherSupplier
            ? 'product_not_added_to_basket_multiple_suppliers'
            : 'product_not_added_to_basket_multiple_packagings'
        }`),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'warning',
        },
      });
    }
  }, [packagingsByBarcodeData, loading]);

  const upsertPackagingByBarCode = (barcode: string): void => {
    packagingsByBarcodeQuery({ variables: { barcode } });
  };

  return { upsertPackagingByBarCode };
}
